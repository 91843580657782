import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';
class CheckRecordModel extends HTTP {
    getCheckRecordDataList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_CHECK_RECORD_DATA_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    getCheckRecordStudentList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_CHECK_RECORD_STUDENT_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getAllDay(data){
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_ALL_DAY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getCheckRecordStudentDetail(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_CHECK_RECORD_STUDENT_DETAIL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getCheckRecordDelete(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CHECK_RECORD_DATA_DELETE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getCheckRecordDayDetail(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_CHECK_RECORD_DATA_DETAIL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getCheckRecordDayDetailSave(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CHECK_RECORD_DATA_SAVE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    getCheckRecordDayDetailDel(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API. GET_CHECK_RECORD_LIST,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }

}
export { CheckRecordModel };
