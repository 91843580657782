import { HTTP } from '@/libs/https.js'
import config from '@/libs/config.js'

class ClassManageModel extends HTTP {
    getClassListByExamId (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CLASS_LIST_EXAMID,
                data: data,
                header: 'application/json',
                success (data) {
                resolve({
                    data,
                    status: 0
                });
                },
                error (error) {
                resolve({
                    error,
                    status: - 1
                })
                }
            });
        });
    }

    getSubjectList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SUBJECTS_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                resolve({
                    data,
                    status: 0
                });
                },
                error (error) {
                resolve({
                    error,
                    status: - 1
                })
                }
            });
        });
    }

    resultesImport (data) {
        return new Promise((resolve, reject) => {
            this.axiosFile({
                url: config.API.RESULTES_IMPORT,
                data: data,
                success (data) {
                resolve({
                    data,
                    status: 0
                });
                },
                error (error) {
                resolve({
                    error,
                    status: - 1
                })
                }
            });
        });
    }

    headcount (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.HEADCOUNT,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    fractioneelBandniveau (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.FRACTIONEEL_BANDNIVEAU,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getRankingSegmentsList(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_RANKING_SEGMENTS_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    saveRankingTemplate(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.FEACTIONAL_SEGMENT_PRESERVATION,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getScoreSectionStatisticsTwo (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCORE_SECTION_STATISTICS_TWO,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getScoreSectionStatisticsThree (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCORE_SECTION_STATISTICS_THREE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    getScoreRanking (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SCORE_RANKING,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    averageScoreStatistics (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.AVERAGE_SCORE_STATISTICE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getExamStatisticsParamDetail (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_EXAM_STATISTICS_PARAM_DETAIL,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getGradeStatisticsOne (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_GRADE_STATISTICS_ONE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getRankingList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_RANKING_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    rankingStatistics (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.RANKING_STATISTICS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    placeKeepingTemplate (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.PLACE_KEEPING_TEMPLATE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    deleteExamParam (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.DELETE_EXAM_PARAM,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    archiveStatisticsSave (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ARCHIVE_STATISTICS_SAVE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    archive (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.ARCHIVE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getGradeStatisticsTwo (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.UPSCALE_STATISTICS_SECONDARY,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getGradeStatisticsThree (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.UPSCALE_STATISTICS_THREE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    individualStudentAchievement (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.INDIVIDUAL_STUDENT_ACHIEVEMENTS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    homogeneityAnalysis (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.HOMOGENEITY_ANALYSIS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    progressRegressionGlance (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.PROGRESS_REGRESSION_GLANCE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getMyGrowUp (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_MY_GROW_UP,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getSubjectOne (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SUBJECT_ONE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getSubjectTwo (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SUBJECT_TWO,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getSubjectThree (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SUBJECT_THREE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    percentageAchievements (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.PERCENTAGE_ACHIEVEMENTS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getSubjectTow (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_SUBJECT_TOW,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }
    getStandard (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_STANDARD,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getArchiveFirstList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ARCHIVE_FIRST_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getArchiveList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_ARCHIVE_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }
    sendResults (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SEND_RESULTS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }
    getExamStatus (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_EXAM_STATUS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }
    SetHjShowExamButton (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SET_HJ_SHOW_EXAM_BUTTON,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }
    setExamStatus (data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SET_EXAM_STATUS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        })
    }

    getProjectDetail(data) {
        return new Promise((resolve, reject) => {
            this.axiosGet({
                url: config.API.GET_PROJECT_DETAILS + `${data}`,
                // data: data,
                // header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    synchronizeExamInfo(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_SYNCHRONIZE_EXAM_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    synchronizeExamScore(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_SYNCHRONIZE_EXAM_SCORE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
}

export { ClassManageModel }
